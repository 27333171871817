

var phaser_config = {
    type: Phaser.AUTO,
    parent: 'game-container',
    width: 450,

    scene: {
        preload: game_preload,
        create: game_create,
        update: game_update,
        render: game_render,
    },
    scale: {
        zoom: 1,
    },
    physics: {
        default: 'matter',
        matter: {
            debug: false,
            gravity: {y:0}
        }
    },
    plugins:{
        scene: [{
            plugin: 'PhaserMatterCollissionPlugin',
            key: 'matterCollision',
            mapping: 'matterCollision'
        }
        ]
    }
};

game = new Phaser.Game(phaser_config);
var score = 0;
var scoreText;

window.game = game; // add to global scope for easy debugging in console

function game_preload () {

    this.load.image("tiles", "assets/tilemaps/beachtiles.png");
    this.load.tilemapTiledJSON("map", "assets/tilemaps/beachmap.json");


    this.load.image('bb','assets/images/robot.png');
    this.load.image('bmanlabel','assets/images/Bman3.png');
    this.load.image('scoreblock','assets/images/scoreblock.png');

    this.load.image('trash1','assets/images/litter/1.png');
    this.load.image('trash2','assets/images/litter/2.png');
    this.load.image('trash3','assets/images/litter/3.png');


}

function game_create (){
    const map = this.make.tilemap({ key: "map" });

    const tileset = map.addTilesetImage("beachtiles", "tiles");
    const beach   =  map.createStaticLayer("Beach", tileset, 0, 0);

    beach.setCollisionByProperty({ collides: true });

    t3 = this.matter.add.image(310,505,'trash1');
    t1 = this.matter.add.image(380,240,'trash3');
    t2 = this.matter.add.image(210,70,'trash2');

    bmanlabel = this.matter.add.image(825,100,'bmanlabel');

    this.bb = new Phaser.Physics.Matter.Sprite(this.matter.world,212,410,'bb');
    this.add.existing(this.bb);

    this.matter.world.on('collisionstart', function (event, bodyA, bodyB) {
        bodyA.gameObject.destroy();
        haveTrash();
    });

    this.add.image(80,40,'scoreblock').setScrollFactor(0);
    scoreText = this.add.text(80, 27, '0', { fontSize: '32px', fill: '#2E82A2' });
    scoreText.setScrollFactor(0);


    const camera = this.cameras.main;
    camera.startFollow(this.bb);
    camera.setBounds(0, 0, map.widthInPixels, map.heightInPixels);

    this.bb.x= map.widthInPixels/2;
    this.bb.y= map.heightInPixels-250;

    this.inputKeys = this.input.keyboard.createCursorKeys();
}

function game_update(){

    const speed = 2.5;

    let playerVelocity = new Phaser.Math.Vector2();
    if (this.inputKeys.right.isDown){
        playerVelocity.x = 1;
    }
    else if (this.inputKeys.left.isDown){
        playerVelocity.x = -1;
    }
    if (this.inputKeys.up.isDown){
        playerVelocity.y = -1;
    }
    else if (this.inputKeys.down.isDown){
        playerVelocity.y = 1;
    }
    playerVelocity.normalize();
    playerVelocity.scale(speed);
    this.bb.setVelocity(playerVelocity.x,playerVelocity.y);
    if (playerVelocity.x ||playerVelocity.y){
        this.bb.rotation = 1.5708+playerVelocity.angle();
    }

}

function haveTrash(){

    score += 10;
    scoreText.setText(score);
    if (score == 10){
        window.helpBlock('Great! You just collected the first trash. Can you collect 2 more?');
    }
    if (score == 20){
        window.helpBlock('Wow, you\'re doing a good job.');
    }
    if (score == 30){
        window.helpBlock('You\'ve got them all. Now look for <strong>Alien B</strong> holding a label.',30000);
    }
    if (score == 40){
        window.helpBlock('You\'ve got them all. Now look for <strong>Alien B</strong> holding a label.',30000);
        window.loadPage('annotate','instant',false);
    }
}

function game_render() {

    this.debug.cameraInfo(this.camera, 32, 32);

}